import React from 'react';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import Layout from '../screens/layout';

const RequireAuth = ({ Component, restricted, page = true }, ...props) => {
    let auth = (restricted === true ? localStorage.getItem("eth_token") : null);
    let location = useLocation();
    const navigate = useNavigate();
    
    if(restricted === true && localStorage.getItem("eth_token") == null){
        return <Navigate to="/" state={{ from: location }} />
    }else{
    
    return  (
            auth != null  ? 
                <Layout>
                    <Component navigate={navigate} {...props} /> 
                </Layout>
            : 
                //(page === true ? <Navigate to="/Auth" state={{ from: location }} /> : <React.Fragment></React.Fragment>)
                (page === true ? <Component navigate={navigate} {...props} /> : <React.Fragment></React.Fragment>)
            );
    }
}


export default RequireAuth;