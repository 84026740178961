import Phaser from 'phaser';

class MapScene extends Phaser.Scene {
  constructor() {
    super({
      key: 'MapScene'
    });
  
    // Inizializza gli stati della scena
    this.coordinatesRef = null;
    this.cameraPosition = { x: 0, y: 0 };
    this.mapSize = { width: 25000, height: 25000 };
    this.blockSize = 100;
    this.chunkSize = 1000;
    this.loadedChunks = new Set();
    this.villageImages = {};
    this.villageImageMappings = {
      'city1': { min: 0, max: 300 },
      'city2': { min: 301, max: 999 },
      'city3': { min: 1000, max: 2999 },
      'city4': { min: 3000, max: 8999 },
      'city5': { min: 9000, max: Infinity }
    };

    // Inizializza villages e naturalElements
    this.villages = [];  // Assicurati che questa variabile sia un array di oggetti
    this.naturalElements = [];  // Assicurati che questa variabile sia un array di oggetti
  

    // Assicurati che le funzioni mantengano il contesto corretto
    this.preload = this.preload.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.onPointerDown = this.onPointerDown.bind(this);
    this.onPointerMove = this.onPointerMove.bind(this);
    this.onPointerUp = this.onPointerUp.bind(this);
    this.showVillageInfo = this.showVillageInfo.bind(this);
    this.loadChunksAround = this.loadChunksAround.bind(this);
    this.createMapChunk = this.createMapChunk.bind(this);
    this.isVillageVisible = this.isVillageVisible.bind(this);
    this.createNaturalElements = this.createNaturalElements.bind(this);
    this.setMapPosition = this.setMapPosition.bind(this);
  }

  async init(){
    const naturalResponse = await fetch('/natural.json');  
    const naturalData = await naturalResponse.json();
    this.naturalElements = naturalData;
    // Aggiungi questo
    const villagesResponse = await fetch('/villages.json');
    const villagesData = await villagesResponse.json();
    this.villages = villagesData;
  }
  
  async preload() {
   
    this.load.image('sfondo', 'assets/img/field.png');
    this.load.image('city1', 'assets/img/mini_01.webp');
    this.load.image('city2', 'assets/img/mini_02.webp');
    this.load.image('city3', 'assets/img/mini_03.webp');
    this.load.image('city4', 'assets/img/mini_04.webp');
    this.load.image('city5', 'assets/img/mini_05.webp');
    if(this.naturalElements.length <=0){
      const naturalResponse = await fetch('/natural.json');  
      const naturalData = await naturalResponse.json();
      this.naturalElements = naturalData;
    }
    this.naturalElements.forEach(element => { console.log(element);
      this.load.image(element.imageName, `assets/natural/${element.imageName}`);
    });
  }

  create() {
    this.cameras.main = this.cameras.add(0, 0, window.innerWidth, window.innerHeight);
    this.cameras.main.setBounds(0, 0, this.mapSize.width, this.mapSize.height);
    //this.physics.world.setBounds(0, 0, mapSize.width, this.mapSize.height);

    this.cameras.main.centerToBounds();
    this.loadedChunks = new Set();


    // Utilizza il plugin di input di Phaser III
    this.input.on('pointerdown', this.onPointerDown);
    this.input.on('pointermove', this.onPointerMove);
    this.input.on('pointerup', this.onPointerUp);

    const coordinatesDiv = document.createElement('div');
    coordinatesDiv.style.position = 'fixed';
    coordinatesDiv.style.top = '20px';
    coordinatesDiv.style.left = '20px';
    coordinatesDiv.style.fontSize = '24px';
    coordinatesDiv.style.color = '#fff';
    document.body.appendChild(coordinatesDiv);
    this.coordinatesRef = coordinatesDiv;

    this.loadChunksAround(0, 0);

    const dotSize = 5;
    this.dot = this.add.circle(0, 0, dotSize, 0xFF0000).setDepth(1).setScrollFactor(0);

    this.createNaturalElements();
  }

  onPointerDown(pointer) {
    this.dragStartPoint = new Phaser.Math.Vector2(pointer.x, pointer.y);
    this.isDragging = true;
  }

  onPointerMove(pointer) {
    if (!pointer.isDown) return;
    if (!this.isDragging) return;

    let dragPoint = new Phaser.Math.Vector2(pointer.x, pointer.y);
    this.cameras.main.scrollX += (this.dragStartPoint.x - dragPoint.x) / this.cameras.main.zoom;
    this.cameras.main.scrollY += (this.dragStartPoint.y - dragPoint.y) / this.cameras.main.zoom;
    this.dragStartPoint.copy(dragPoint);
  }

  onPointerUp(pointer) {
    this.isDragging = false;
  }

  showVillageInfo(village) {
    if (!this.isDragging) {
      const message = `Punteggio: ${village.punteggio}\nCoordinate X: ${village.x}\nCoordinate Y: ${village.y}\nID Proprietario: ${village.id_proprietario}\nBonus: ${village.bonus ? 'Sì' : 'No'}`;
      //alert(message);
    }
  }

  async loadChunksAround(scene, chunkX, chunkY) {
    const range = 2;

    for (let x = -range; x <= range; x++) {
      for (let y = -range; y <= range; y++) {
        const loadX = chunkX + x;
        const loadY = chunkY + y;
        const chunkKey = `${loadX}_${loadY}`;

        if (!this.loadedChunks.has(chunkKey)) {
          await this.createMapChunk(scene, loadX * this.chunkSize, loadY * this.chunkSize);
          this.loadedChunks.add(chunkKey);
        }
      }
    }

    this.villages.forEach((village) => {
      const blockX = this.blockSize * village.x;
      const blockY = this.blockSize * village.y;

      if (this.isVillageVisible(blockX, blockY)) {
        let villageImageKey;

        for (const key in this.villageImageMappings) {
          const range = this.villageImageMappings[key];
          if (village.punteggio >= range.min && village.punteggio <= range.max) {
            villageImageKey = key;
            break;
          }
        }

        if (!this.villageImages[village.id]) {
          this.villageImages[village.id] = this.add.image(blockX - (this.blockSize / 2), blockY - (this.blockSize / 2), villageImageKey);
        } else {
          this.villageImages[village.id].setPosition(blockX - (this.blockSize / 2), blockY - (this.blockSize / 2));
        }

        this.villageImages[village.id].setInteractive();
        this.villageImages[village.id].on('pointerup', () => this.showVillageInfo(village));
      }
    });
  }

  async createMapChunk(scene, startX, startY) {
    const chunkSize = 10;

    for (let x = 0; x < chunkSize; x++) {
      for (let y = 0; y < chunkSize; y++) {
        const blockX = startX + x * this.blockSize;
        const blockY = startY + y * this.blockSize;

        const village = this.villages.find(village => village.x === blockX / this.blockSize && village.y === blockY / this.blockSize);

        if (village) {
          let villageImageKey;

          for (const key in this.villageImageMappings) {
            const range = this.villageImageMappings[key];
            if (village.punteggio >= range.min && village.punteggio <= range.max) {
              villageImageKey = key;
              break;
            }
          }

          if (!this.villageImages[village.id]) {
            this.villageImages[village.id] = scene.add.image(blockX - (this.blockSize / 2), blockY - (this.blockSize / 2), villageImageKey);
          } else {
            this.villageImages[village.id].setPosition(blockX - (this.blockSize / 2), blockY - (this.blockSize / 2));
          }
        } else {
          this.add.image(blockX - (this.blockSize / 2), blockY - (this.blockSize / 2), 'sfondo');
        }
      }
    }
  }

  isVillageVisible(x, y) {
    const camera = this.cameras.main;
    const leftEdge = camera.worldView.x;
    const rightEdge = leftEdge + camera.width;
    const topEdge = camera.worldView.y;
    const bottomEdge = topEdge + camera.height;

    return x >= leftEdge && x <= rightEdge && y >= topEdge && y <= bottomEdge;
  }

  createNaturalElements() {
    this.naturalElements.forEach(element => {
      const elementX = element.x * this.blockSize;
      const elementY = element.y * this.blockSize;

      this.add.image(elementX, elementY, element.imageName).setOrigin(0, 0).setDepth(2);
    });
  }

  update() {
    const cameraX = this.cameras.main.scrollX;
    const cameraY = this.cameras.main.scrollY;
    const currentChunkX = Math.floor(cameraX / this.chunkSize);
    const currentChunkY = Math.floor(cameraY / this.chunkSize);
    this.loadChunksAround(this, currentChunkX, currentChunkY);

    const roundedX = Math.floor(cameraX);
    const roundedY = Math.floor(cameraY);
    this.cameraPosition = { x: roundedX, y: roundedY };

    this.coordinatesRef.textContent = `PX: ${roundedX}, PY: ${roundedY} | X:${Math.floor(roundedX / this.blockSize)}, Y:${Math.floor(roundedY / this.blockSize)}`;
  }

  setMapPosition(x, y) {
    this.cameras.main.scrollX += x / this.cameras.main.zoom;
    this.cameras.main.scrollY += y / this.cameras.main.zoom;
  }
}

export default MapScene;
