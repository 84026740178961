// GlobalFunctionsContext.js
import React, { createContext } from 'react';
import {CityReq, CityResReq} from '../requests/cityReq';

export const GlobalFunctionsContext = createContext();

export class GlobalFunctionsProvider extends React.Component {
    state = {
        resources: null,
        cityBuildings: null
    };

  myGlobalFunction = async (props = undefined) => {
    // Implementa la logica della tua funzione globale qui, utilizzando anche i props passati
    if(props === undefined ){
      const req = await CityReq()
      const reqRes = await CityResReq();

      this.setState({
          resources: reqRes.length > 0 ? reqRes : [],
          cityBuildings: req.city.builds.length > 0 ? req.city.builds : []
      });
    }
  };

  render() {
    const { cityBuildings } = this.state;
    const reload = this.props.reload(this.state);

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {reload})
    );

    return (
      <GlobalFunctionsContext.Provider value={{ myGlobalFunction: this.myGlobalFunction }}>
        {childrenWithProps}
      </GlobalFunctionsContext.Provider>
    );
  }
}

export const useGlobalFunctions = () => {
  return React.useContext(GlobalFunctionsContext);
};
