import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT_SERVER;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('eth_token');

async function CityReq() {
    try {
        
        const response = await axios.post('/?pg=City', { leack: 'key' });
        return response.data;
    } catch (error) {
        console.error('Errore nella richiesta:', error);
        throw error; // Rilancia l'errore per gestirlo nel chiamante
    }
}

async function CityBuilds() {
    try {
        
        const response = await axios.post('/?pg=City&act=Builds', { leack: 'key' });
        return response.data;
    } catch (error) {
        console.error('Errore nella richiesta:', error);
        throw error; // Rilancia l'errore per gestirlo nel chiamante
    }
}

async function CityResReq() {
    try {
        
        const response = await axios.get('/?pg=City&act=Resources');

        return response.data;
    } catch (error) {
        console.error('Errore nella richiesta:', error);
        throw error; // Rilancia l'errore per gestirlo nel chiamante
    }
}

export {
    CityReq,
    CityResReq,
    CityBuilds
};
