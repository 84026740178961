// VillageScene.js
import Phaser from 'phaser';

import Logo from '../../../assets/img/logo.png';
//import earth from '../../../assets/img/gameassets/img/village/back2.jpg';
//import OutlinePipeline from './OutlinePipeline';
import imgHover from '../../../assets/img/hovered.png'
import audioAmbient1 from '../../../assets/audio/ambient1.m4a';


let buildsN = 0;
let startBuildsN = 0;

class VillageScene extends Phaser.Scene {
    constructor(config, props) {
        super({ key: 'VillageScene' }); // Assicurati che la chiave sia la stessa della configurazione della scena
        this.props = props;

        this.state = {
            city: {
                options: {
                    music: {
                        play: true, 
                        volume: .5
                    }, 
                    debug: true
                },
                cityZoom: 85
            },
            buildings: [
                {
                    name: "Mura",
                    desc: "",
                    image: "assets/img/village/mura/mura-1.png",
                    polygon: [54,137,10,194,45,220,97,255,217,298,280,274,280,234,359,241,363,282,422,294,541,258,624,229,694,191,697,169,718,189,705,224,647,268,528,318,397,359,230,358,79,320,2,221,19,142],
                    level: 1,
                    maxLevel:3,
                    id: "Wall",
                    pos: {
                        x: 2130,
                        y: 395
                    },
                    size: {
                        w: 750,
                        h: 380
                    },
                    zindex: 1,
                    load: 100
                },
                {
                    name: "Officina",
                    id: "Garage",
                    desc: "",
                    image: "assets/img/village/officina/officina-1.png",
                    polygon: [19,50,56,61,116,55,139,44,139,25,82,14,54,22],
                    level: 1,
                    maxLevel:2,
                    pos: {
                        x: 1946,
                        y: 465
                    },
                    size: {
                        w: 160,
                        h: 79
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Quartier generale",
                    desc: "",
                    id: "Main",
                    image: "assets/img/village/quartier/qg-1.png",
                    polygon: [6,160,16,157,40,165,133,131,131,95,113,93,119,49,82,48,4,117],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 2125,
                        y: 201
                    },
                    size: {
                        w: 157,
                        h: 194
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Caserma",
                    desc: "",
                    id: "Barracks",
                    image: "assets/img/village/caserma/caserma-1.png",
                    polygon: [13,64,77,77,120,49,97,45,93,7,66,2,33,9],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 2190,
                        y: 414
                    },
                    size: {
                        w: 130,
                        h: 101
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Stalla",
                    desc: "",
                    id: "Stable",
                    image: "assets/img/village/stalla/stalla-1.png",
                    polygon: [3,44,32,57,81,46,57,10,4,22],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 2258,
                        y: 268
                    },
                    size: {
                        w: 140,
                        h: 70
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Accademia",
                    desc: "",
                    id: "Snob",
                    image: "assets/img/village/accademia/accademia-1.png",
                    polygon: [22,10,9,28,9,116,77,134,132,116,129,42,88,35,71,2,57,21,56,30,35,37],
                    level: 1,
                    maxLevel:1,
                    pos: {
                        x: 1933,
                        y: 260
                    },
                    size: {
                        w: 150,
                        h: 160
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Fabbro",
                    desc: "",
                    id: "Smith",
                    image: "assets/img/village/fabbro/fabbro-1.png",
                    polygon: [5,64,89,73,106,67,106,31,68,27,34,4,4,0],
                    level: 1,
                    maxLevel:2,
                    pos: {
                        x: 2400,
                        y: 350
                    },
                    size: {
                        w: 150,
                        h: 95
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Punto di raduno",
                    desc: "",
                    id: "Place",
                    image: "assets/img/village/raduno/raduno-1.png",
                    polygon: [0,33,4,97,93,78,130,26,133,1,46,1,52,25],
                    level: 1,
                    maxLevel:2,
                    pos: {
                        x: 2330,
                        y: 430
                    },
                    size: {
                        w: 116,
                        h: 73
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Statua",
                    desc: "",
                    id: "Statue",
                    image: "assets/img/village/statua/statua.png",
                    polygon: [3,69,43,74,41,10,28,3,11,5],
                    level: 1,
                    maxLevel:1,
                    pos: {
                        x: 2140,
                        y: 310
                    },
                    size: {
                        w: 41,
                        h: 79
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Mercato",
                    desc: "",
                    id: "Market",
                    image: "assets/img/village/mercato/mercato-1.png",
                    polygon: [12,65,79,86,149,76,153,54,98,41,57,27,50,14,35,11,12,37],
                    level: 1,
                    maxLevel:1,
                    pos: {
                        x: 1880,
                        y: 360
                    },
                    size: {
                        w: 190,
                        h: 103
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Taglialegna",
                    desc: "",
                    id: "Wood",
                    image: "assets/img/village/legna/legna-1.png",
                    polygon: [2,71,175,68,179,3,0,2],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 1460,
                        y: 460
                    },
                    size: {
                        w: 180,
                        h: 72
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Cava di argilla",
                    desc: "",
                    id: "Stone",
                    image: "assets/img/village/argilla/argilla-1.png",
                    polygon: [292,64,295,159,100,159,96,101,134,78,194,65],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 2600,
                        y:580
                    },
                    size: {
                        w: 300,
                        h: 200
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Miniera di ferro",
                    desc: "",
                    id: "Iron",
                    image: "assets/img/village/ferro/ferro-1.png",
                    polygon: [0,9,78,17,83,55,121,61,110,93,0,92],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 1635,
                        y: 250
                    },
                    size: {
                        w: 195,
                        h: 99
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Fattoria",
                    desc: "",
                    id: "Farm",
                    image: "assets/img/village/fattoria/fattoria-1.png",
                    polygon: [0,7,110,6,123,138,3,155],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 2680,
                        y: 205
                    },
                    size: {
                        w: 150,
                        h: 148
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Magazzino",
                    desc: "",
                    id: "Storage",
                    image: "assets/img/village/magazzino/magazzino-1.png",
                    polygon: [20,76,65,94,107,82,107,30,88,21,37,27,17,44],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 2045,
                        y: 390
                    },
                    size: {
                        w: 120,
                        h: 98
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Nascondiglio",
                    desc: "",
                    id: "Hide",
                    image: "assets/img/village/nascondiglio/nascondiglio-1.png",
                    polygon: [15,39,9,21,53,13,71,32,64,49,32,49],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 2510,
                        y: 150
                    },
                    size: {
                        w: 74,
                        h: 61
                    },
                    zindex: 2,
                    load: 100
                }
            ]
        };
        
    }

    init(){
        this.cameras.main.setBackgroundColor('#000');
        //  Sphinx
        this.location1 = new Phaser.Math.Vector2(766, 1090);
        /*this.renderer.addPipeline(
            OutlinePipeline.KEY,
            new OutlinePipeline(this)
        );*/
    }

    preload() {

        // Carica le immagini delle strutture

        this.load.image('earth', 'assets/img/village/back2.jpg');
        this.load.audio('ambient', audioAmbient1);
        this.load.image("test", imgHover); 



        this.state.buildings.forEach(build => {
            const imageKey = `${build.id}${build.level}`;
            this.load.image(imageKey, build.image); 
        });


        var progressBar = this.add.graphics();
        var progressBox = this.add.graphics();
        
        var width = this.cameras.main.width;
        var height = this.cameras.main.height;

        progressBox.fillStyle(0x222222, 0.8);
        progressBox.fillRect(width / 2 - 160 , height / 2 - 25, 320, 50);
        var loadingText = this.make.text({
            x: width / 2,
            y: height / 2 - 50,
            text: 'Loading...',
            style: {
                font: '20px monospace',
                fill: '#ffffff'
            }
        });
        loadingText.setOrigin(0.5, 0.5);
        
        var percentText = this.make.text({
            x: width / 2,
            y: height / 2,
            text: '0%',
            style: {
                font: '18px monospace',
                fill: '#ffffff'
            }
        });
        percentText.setOrigin(0.5, 0.5);
        
        var assetText = this.make.text({
            x: width / 2,
            y: height / 2 + 50,
            text: '',
            style: {
                font: '18px monospace',
                fill: '#ffffff'
            }
        });
        assetText.setOrigin(0.5, 0.5);
        
        this.load.on('progress', function (value) {
            percentText.setText(parseInt(value * 100) + '%');
            progressBar.clear();
            progressBar.fillStyle(0xffffff, 1);
            progressBar.fillRect(width / 2 - 150 , height / 2 - 14.5, 300 * value, 30);
        });
        
        this.load.on('fileprogress', function (file) {
            assetText.setText('Loading asset: ' + file.key);
        });
        this.load.on('complete', function () {
            progressBar.destroy();
            progressBox.destroy();
            loadingText.destroy();
            percentText.destroy();
            assetText.destroy();
        });

        
    }

    create() { 

        // Aggiorna il lancio della scena mappa solo se non è già attiva
        if (!this.scene.isActive('MapScene')) {
            this.scene.launch('MapScene');
        }


        const ambient = this.sound.add('ambient',{ loop: true, volume: this.state.city.options.music.volume });
        //this.sound.setDecodedCallback([ ambient ], start, this);
        if(this.state.city.options.music.play){
            ambient.play();
        }else{
            ambient.stop();
        }

        let text;
        
        //Inizializzazione camera mondo
        const earth_ = this.add.image(0, 0, 'earth').setOrigin(0);
        const earthSize = {
            w: this.textures.get('earth').getSourceImage().width,
            h: this.textures.get('earth').getSourceImage().height
        }
        //this.cameras.add(0, 0, earthSize.w, earthSize.h);
        let cam = this.cameras.main;
        
        cam.setBounds(0, 0, earthSize.w, earthSize.h);
        
        cam.setZoom(.8);
        
        cam.centerOn(0, 0);
        
        cam.scrollX = (earthSize.w /2) - (window.innerWidth / 2) + 100;
        cam.scrollY = 0;

        let prevX = 0, prevY = 0;
        
        //movimento camera falso
        let dragenabled = false;
        
        //Informazioni camera di test 
         if(this.state.city.options.debug){
            text = this.add.text(10, 10, '0').setText('Click to move').setScrollFactor(0);
            text.setShadow(1, 1, '#000000', 2);
            text.setText(['Click to move', 'x: ' + cam.scrollX, 'y: ' + cam.scrollY ]);
        }
         
        //cam.ignore([text]);

        //cam.ignore(earth_);
        
        
        //creazione della tooltip per il nome della struttura
        let toolTipText = this.add.text( 0, 0, '', { fontFamily: 'Arial', color: '#000' }).setOrigin(0);
        let toolTip =  this.add.rectangle( 0, 0, toolTipText.width + 10, toolTipText.height + 10, 0xF2D787).setOrigin(0);

        let toolTipGenInfoText = this.add.text( 0, 0, '', { fontFamily: 'Arial', color: '#000' }).setOrigin(0);
        let toolTipGenInfo =  this.add.rectangle( 0, 0, toolTipGenInfoText.width + 10, toolTipGenInfoText.height + 10, 0xF2D787).setOrigin(0);


        toolTip.alpha = 0;
        //posizionamento zindex su 1
        toolTip.setDepth(10);
        toolTipText.setDepth(11);

        toolTipGenInfo.alpha = 0;
        toolTipGenInfo.setDepth(10);
        toolTipGenInfoText.setDepth(11);

        //evento tooltip sul mondo
        this.input.setPollOnMove();
        this.input.on('gameobjectover', function (pointer, gameObject) {
            this.tweens.add({
                targets: [toolTip, toolTipText],
                alpha: {from:0, to:1},
                repeat: 0,
                duration: 500
            });
            this.tweens.add({
                targets: [toolTipGenInfo, toolTipGenInfoText],
                alpha: {from:0, to:1},
                repeat: 0,
                duration: 500
            });
        }, this);

        this.input.on('gameobjectout', function (pointer, gameObject) {
            toolTip.alpha = 0;
            toolTipText.alpha = 0;
            toolTipGenInfo.alpha = 0;
            toolTipGenInfoText.alpha = 0;
        });

        startBuildsN = this.state.buildings.length;
        
        this.state.buildings.forEach(build => {
            
            
            //richiamo assets delle strutture + posizionamento
            
            const bld = this.add.sprite(build.pos.x,build.pos.y, build.id + (build.level > build.maxLevel ? build.maxLevel :  build.level)).setDisplaySize(build.size.w,build.size.h);                            
            
            bld.setDepth(build.zindex);

            //creazione poligono struttura per il mouseenter
            var shape = new Phaser.Geom.Polygon(build.polygon);
            //creazione interazione asset struttura
            bld.setInteractive(shape, Phaser.Geom.Polygon.Contains);
            
            let screenMove = false
            //tooltip visibile su struttura in hover                        
            bld.on('pointermove', function (pointer) {
                toolTip.x = pointer.worldX + 20; //+ pointer.x;
                toolTip.y = pointer.worldY + 20;
                toolTipText.x = pointer.worldX + 25;
                toolTipText.y = pointer.worldY + 25;
                toolTipText.text = build.name;
                toolTip.width = toolTipText.width + 10;

                toolTipGenInfo.x = pointer.worldX + 20; //+ pointer.x;
                toolTipGenInfo.y = pointer.worldY + 55;
                toolTipGenInfoText.x = pointer.worldX + 25;
                toolTipGenInfoText.y = pointer.worldY + 60;
                toolTipGenInfoText.text = "Level: " + build.level ;
                toolTipGenInfo.width = toolTipGenInfoText.width < 160 ? 160 : toolTipGenInfoText.width + 10;
                toolTipGenInfo.height = toolTipGenInfoText.height + 10;

                screenMove= true;
            });


            //  Input Event listeners
            const th = this;
            let ok_ = true;
            document.addEventListener('mousedown', (e) =>{
                if(e.target.tagName.toLowerCase() != "canvas"){
                    ok_ = false;
                } else ok_ = true;

                screenMove = false
            });
                                    
            //all'evento mouse dentro la struttura mostra la struttura in hover
            //bld.on('pointerover', function () {
            bld.on('pointerover', function (e) {
                th.input.setDefaultCursor('pointer');
                this.setTint(0xffd405);
                //this.setShadow(2, 2, "#000000", 2, true, true);
            });

            //contrario del primo, all'uscita del mouse fuori dalla struttura hover ritorni come impostata inizialmente
            bld.on('pointerout', function () {
                th.input.setDefaultCursor('default');
                this.clearTint();
            });
            

            //click all'interno della struttura quando è in hover e quando no
            
            bld.on('pointerup', () => {
                if(ok_ && !screenMove){
                    this.clickPoint(build)
                }
            }, this);
            
        });
        
        
    
        //style mouse manina 
        this.input.on('pointerdown', function(e){
            dragenabled = true;
            prevX = e.x;
            prevY = e.y;
        }, this);

        //azioni di movimento della mappa di gioco.
        document.addEventListener("mouseleave", function(event){
            if(event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)){
                dragenabled = false;
            }
        });

        this.input.on('pointerup', function(e){
            dragenabled = false;
            
        }, this);

        this.input.on('pointermove', function(e){
            if(dragenabled == false) return;
            //cam.centerOn(prevX - e.x, prevY - e.y);
            cam.scrollX += prevX - e.x;
            prevX = e.x;
            cam.scrollY += prevY- e.y;
            prevY = e.y;

            if(this.state.city.options.debug)
                text.setText(['Click to move', 'x: ' + cam.scrollX, 'y: ' + cam.scrollY ]);

        }, this);
        
        this.scene.launch('VillageScene');
    }

    update() {
        //console.log(this.state);
        this.cameras.main.setZoom(this.state.city.cityZoom / 100);

        
        buildsN = this.state.buildings.length;
        
        if(!this.state.buildings || buildsN != startBuildsN){
            this.registry.destroy();
            this.events.off();
            this.scene.restart();
            startBuildsN = buildsN;
        }
    }
}

export default VillageScene;
