import React from 'react'
import { Header } from 'ethernial-ui';
import {Map} from 'ethernial-map';

class MapPage extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            naturalElements: [],
            villages: []
        }
    }

    componentDidMount = async() => {
        
        
    }

    componentWillUnmount(){
        window.removeEventListener('resize', () => {});        
    }

    render(){

        
        const options = [{
            navLink: {
            resources: "",
            city: "/",
            map: "/Map",
            stat: "",
            report: "",
            message: "",
            quest: "",
            }
        }]

        console.log(this.state.naturalElements);
        return(
            <div className="ethernial-ui">
                <Header className="fixed !min-h-[110px]" options={options} />
                <Map naturalElements={this.state.naturalElements} villages={this.state.villages} />
            </div>
        )
    }
}

export default MapPage;