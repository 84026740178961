import React, {useState, useEffect} from 'react'
import InitReq from '../../requests/init';
import {useNavigate,  useParams } from 'react-router-dom';

const Knockknock = () => {
    const { res } = useParams();
    const split = res.split('@keygen:');
    const navigate = useNavigate();

    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const req = await InitReq(split[0], split[1]);
                setData(req);
                if(req){
                    navigate('/');
                }
            } catch (error) {
                console.error('Errore durante il recupero dei dati:', error);
            }
        };

        fetchData();
    }, []);


    return (
        <div >Synctonizzation...</div>
    );
};

export default Knockknock;
