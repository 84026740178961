import React from 'react'
import {City} from 'ethernial-city'
import {Modal} from 'ethernial-ui';
import { SealQuestion, X} from "@phosphor-icons/react";
import Main from './Buildings/Main';

class Village extends React.Component{

    constructor(props){
        super(props);

        
        this.state = {
            showModal: false,
            modalInfo:{},
            resources: [],
            city: {
                options: {
                    music: {
                        play: false, 
                        volume: .5
                    }, 
                    debug: false
                },
                cityZoom: 85
            },
            buildings: this.props.cityBuildings,
            /* buildings: [
                {
                    name: "Mura",
                    desc: "",
                    image: "/village/mura/mura-1.png",
                    polygon: [54,137,10,194,45,220,97,255,217,298,280,274,280,234,359,241,363,282,422,294,541,258,624,229,694,191,697,169,718,189,705,224,647,268,528,318,397,359,230,358,79,320,2,221,19,142],
                    level: 1,
                    maxLevel:3,
                    id: "Wall",
                    pos: {
                        x: 2130,
                        y: 395
                    },
                    size: {
                        w: 750,
                        h: 380
                    },
                    zindex: 1,
                    load: 100
                },
                {
                    name: "Officina",
                    id: "Garage",
                    desc: "",
                    image: "/village/officina/officina-1.png",
                    polygon: [19,50,56,61,116,55,139,44,139,25,82,14,54,22],
                    level: 1,
                    maxLevel:2,
                    pos: {
                        x: 1946,
                        y: 465
                    },
                    size: {
                        w: 160,
                        h: 79
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Quartier generale",
                    desc: "",
                    id: "Main",
                    image: "/village/quartier/qg-1.png",
                    polygon: [6,160,16,157,40,165,133,131,131,95,113,93,119,49,82,48,4,117],
                    level: 4,
                    maxLevel:3,
                    pos: {
                        x: 2125,
                        y: 201
                    },
                    size: {
                        w: 157,
                        h: 194
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Caserma",
                    desc: "",
                    id: "Barracks",
                    image: "/village/caserma/caserma-1.png",
                    polygon: [13,64,77,77,120,49,97,45,93,7,66,2,33,9],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 2190,
                        y: 414
                    },
                    size: {
                        w: 130,
                        h: 101
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Stalla",
                    desc: "",
                    id: "Stable",
                    image: "/village/stalla/stalla-1.png",
                    polygon: [3,44,32,57,81,46,57,10,4,22],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 2258,
                        y: 268
                    },
                    size: {
                        w: 140,
                        h: 70
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Accademia",
                    desc: "",
                    id: "Snob",
                    image: "/village/accademia/accademia-1.png",
                    polygon: [22,10,9,28,9,116,77,134,132,116,129,42,88,35,71,2,57,21,56,30,35,37],
                    level: 1,
                    maxLevel:1,
                    pos: {
                        x: 1933,
                        y: 260
                    },
                    size: {
                        w: 150,
                        h: 160
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Fabbro",
                    desc: "",
                    id: "Smith",
                    image: "/village/fabbro/fabbro-1.png",
                    polygon: [5,64,89,73,106,67,106,31,68,27,34,4,4,0],
                    level: 1,
                    maxLevel:2,
                    pos: {
                        x: 2400,
                        y: 350
                    },
                    size: {
                        w: 150,
                        h: 95
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Punto di raduno",
                    desc: "",
                    id: "Place",
                    image: "/village/raduno/raduno-1.png",
                    polygon: [0,33,4,97,93,78,130,26,133,1,46,1,52,25],
                    level: 1,
                    maxLevel:2,
                    pos: {
                        x: 2330,
                        y: 430
                    },
                    size: {
                        w: 116,
                        h: 73
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Statua",
                    desc: "",
                    id: "Statue",
                    image: "/village/statua/statua.png",
                    polygon: [3,69,43,74,41,10,28,3,11,5],
                    level: 1,
                    maxLevel:1,
                    pos: {
                        x: 2140,
                        y: 310
                    },
                    size: {
                        w: 41,
                        h: 79
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Mercato",
                    desc: "",
                    id: "Market",
                    image: "/village/mercato/mercato-1.png",
                    polygon: [12,65,79,86,149,76,153,54,98,41,57,27,50,14,35,11,12,37],
                    level: 1,
                    maxLevel:1,
                    pos: {
                        x: 1880,
                        y: 360
                    },
                    size: {
                        w: 190,
                        h: 103
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Taglialegna",
                    desc: "",
                    id: "Wood",
                    image: "/village/legna/legna-1.png",
                    polygon: [2,71,175,68,179,3,0,2],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 1460,
                        y: 460
                    },
                    size: {
                        w: 180,
                        h: 72
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Cava di argilla",
                    desc: "",
                    id: "Stone",
                    image: "/village/argilla/argilla-1.png",
                    polygon: [292,64,295,159,100,159,96,101,134,78,194,65],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 2600,
                        y:580
                    },
                    size: {
                        w: 300,
                        h: 200
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Miniera di ferro",
                    desc: "",
                    id: "Iron",
                    image: "/village/ferro/ferro-1.png",
                    polygon: [0,9,78,17,83,55,121,61,110,93,0,92],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 1635,
                        y: 250
                    },
                    size: {
                        w: 195,
                        h: 99
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Fattoria",
                    desc: "",
                    id: "Farm",
                    image: "/village/fattoria/fattoria-1.png",
                    polygon: [0,7,110,6,123,138,3,155],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 2680,
                        y: 205
                    },
                    size: {
                        w: 150,
                        h: 148
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Magazzino",
                    desc: "",
                    id: "Storage",
                    image: "/village/magazzino/magazzino-1.png",
                    polygon: [20,76,65,94,107,82,107,30,88,21,37,27,17,44],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 2045,
                        y: 390
                    },
                    size: {
                        w: 120,
                        h: 98
                    },
                    zindex: 2,
                    load: 100
                },
                {
                    name: "Nascondiglio",
                    desc: "",
                    id: "Hide",
                    image: "/village/nascondiglio/nascondiglio-1.png",
                    polygon: [15,39,9,21,53,13,71,32,64,49,32,49],
                    level: 1,
                    maxLevel:3,
                    pos: {
                        x: 2510,
                        y: 150
                    },
                    size: {
                        w: 74,
                        h: 61
                    },
                    zindex: 2,
                    load: 100
                }
            ], */
       };
    }

    componentDidMount (){
        

        this.setState({
            resources: this.props.resources,
            cityBuildings: this.props.buildings
        });
    }

    actionClose = () =>{
        this.setState({
            showModal: !this.state.showModal
        });
    }

    actionModal = (e) => {
        this.setState({
            showModal: true,
            modalInfo:e
        });
    }

    render(){

        const {city, buildings,showModal, modalInfo, } = this.state;
        const {resources, cityBuildings} = this.props;
        
        return (
            <div >
                <City className={"pt-8"} zoom={city.cityZoom} buildings={buildings} cityBuilds={cityBuildings} action={this.actionModal} options={city.options} />

                <Modal 
                    id={1} 
                    action={() => this.actionClose()} 
                    show={showModal} 
                    title={showModal ? modalInfo.buildInfo.name : "undefined"} 
                    body={showModal ? 
                            modalInfo.buildImage.build_func_id === "Main" ?
                                <Main resourcesCity={resources} cityBuildings={cityBuildings} />
                            :
                            "undefined"
                        : "undefined" } 
                    bck={false} 
                    iconHelp={<SealQuestion size={23} className='mt-1' />}
                    iconClose={<X size={23} className='mt-1' />}
                />
            </div>
        );
    }
    
}

export default Village;