import axios from 'axios';

async function Init(key, token) {
    try {
        axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT_SERVER;
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.defaults.headers.post['Accept'] = 'application/json';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

        const response = await axios.post('/?pg=City', { leack: key });
        localStorage.setItem('eth_token', token);
        localStorage.setItem('eth_user', JSON.stringify(response.data.user));
        return response.data;
    } catch (error) {
        console.error('Errore nella richiesta:', error);
        throw error; // Rilancia l'errore per gestirlo nel chiamante
    }
}

export default Init;
