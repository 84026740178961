import React from 'react';
import ReactDOM from 'react-dom/client';
import './input.css';
import RequireAuth from './components/RequireAuth';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes ,Route } from 'react-router-dom';
import Village from './screens/Village';
import Knockknock from './screens/Knockknock';
import Map from './screens/map';
import Game from './screens/game';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<Routes>
			<Route exact path="/knockknock/:res" element={<RequireAuth Component={Knockknock} page={true} restricted={false} />} />
			<Route exact path="/" element={<RequireAuth Component={Village} page={false} restricted={true} />} />
			<Route exact path="/Map" element={<RequireAuth Component={Map} page={true} restricted={true} />} />
			<Route exact path="/Game" element={<RequireAuth Component={Game} page={true} restricted={true} />} />
			<Route path="*" element={<h1>Errore 404</h1>} exact />
			{/*<Route path="/:token" element={<Initialize />} exact />
			<Route element={<Map />} path="/Map" />
			<Route path="/404" element={<Err404 />} exact />
			*/}
			{/*
			<PublicRoute component={Api} restricted={false} path="/api" />
			<PublicRoute restricted={false} path="*" component={Index} />*/}
		</Routes>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
