import React, {Component} from 'react';
import Style from './style.module.css';
import './my-style.css';
import { MapPin,CellSignalFull, GearSix, Coins,Info,SealQuestion, X } from "@phosphor-icons/react";
import {BuildsList, BuildSet,BuildsQueue,BuildCancel} from '../../../../requests/buildsList';
import {CityBuilds,CityResReq} from '../../../../requests/cityReq';
import {ToolTip,Button, Modal} from 'ethernial-ui'
import { GlobalFunctionsContext } from '../../../../global_request';
import ModalInfo from './ModalInfo';

import Countdown from 'react-countdown';


const tim = (time, type = 1) =>{
    var countDownDate = time;

    var x = setInterval(function(){
        var now = new Date().getTime();

        // Find the distance between now an the count down date
        var distance = now - countDownDate;
        
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        // If the count down is over, write some text 
        if (distance < 0) {
            clearInterval(x);
            //(type == 1 ? window.location.reload() : false);
            return "Completato";
        }
        
        // Output the result in an element with id="demo"
        return days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
        
    }, 100);
}

class Index extends Component{
    static contextType = GlobalFunctionsContext;

    constructor(props){
        super(props);

        this.state = {
            buildings: [],
            cityBuildings: [],
            buildingsQueue: [],
            modalInfo: {
                title: "",
                body: null,
                id: null,
                start: false,
            }
        }
    }


    async componentDidMount(){
        const __this = this;

        const buildings = await BuildsList();
        const buildingsQueue = await BuildsQueue();
        
        __this.setState({ buildings: buildings, buildingsQueue:buildingsQueue, cityBuildings: this.props.cityBuildings });
 
    }

    openModal = (e, row) => {

        this.setState({
            modalInfo: {
                title: row.name,
                body: <ModalInfo body={row} />,
                id: row.id + "-modalInfoStructure",
                start: true
            }
        })

        
    }

    stateModal = () => {
        const modalInfo = this.state.modalInfo;

        modalInfo.start = !this.state.modalInfo.start;
        
        this.setState(modalInfo);

    }

    handleConstruction = async (e, id) =>{
        e.preventDefault();

        const { myGlobalFunction } = this.context;
        const buildings = await BuildSet(id);
        if(buildings.success){ 
            this.setState({
                buildingsQueue: [...this.state.buildingsQueue, buildings.success],
            });
           

            myGlobalFunction();

        }else{
            alert(buildings.error)
        } 
    }

    removeBuilder = async (e,id) =>{
        e.preventDefault();
        const { myGlobalFunction } = this.context;

        const buildings = await BuildCancel(id);
        if(buildings.success){
            this.setState(prevState => ({
                buildingsQueue: prevState.buildingsQueue.filter(item => item.id !== id)
            }));
            myGlobalFunction();
        }
    }

    render(){

        
        const this_ = this;
        const { buildings, buildingsQueue, cityBuildings } = this.state;
        const {resourcesCity, infoModal} = this.props;

        return (
            <div className={Style.contentList}>
                {/* <div className="mb-10">
                    <div className="grid grid-cols-3 gap-4">
                        <div>
                            <img src={"https://etr.altervista.org/cdn-v1/" + infoModal.image} alt="" />
                        </div>
                        <div className='col-span-2'>
                            <h1 className='text-xl font-bold'>Asd</h1>
                            <p>lorem ipsum sit amet</p>
                        </div>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-md">
                        <h2 style={{marginBottom: 20}}>Lista strutture in costruzione</h2>
                    </div>
                </div>
                <div>
                    <table style={{width: "100%", marginBottom: 20}} className={Style.table}>
                        <thead>
                            <tr>
                                <th><ToolTip placement="bottom" title={<p>Lista strutture in costruzione</p>}><MapPin size={17} /></ToolTip></th>
                                <th><ToolTip placement="bottom" title={<p>Livello costruzione</p>}><CellSignalFull size={17} /></ToolTip></th>
                                <th></th>
                                <th><ToolTip placement="bottom" title={<p>Azioni</p>}><GearSix size={17}/></ToolTip></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                buildingsQueue.length > 0 ?
                                    buildingsQueue.map((row, index) => {
                                        
                                        // Renderer callback with condition
                                        const renderer = ({ years, month, days, hours, minutes, seconds, completed }) => {
                                            if (completed) {
                                                // Render a completed state
                                                
                                                if (completed) {
                                                    // Render a completed state
                                                    CityBuilds()
                                                    .then(result => { 
                                                        //this_.props.setBuildingCity(result);
                                                        this_.setState(prevState => ({
                                                            buildings: prevState.buildings.map(item => {
                                                                if (item.id === row.build.id) {
                                                                    // Aggiorna l'oggetto con l'ID corrispondente
                                                                    const filterBuild = result.filter((b, i) => b.buildInfo.id === item.id)[0]; 
                                                                    return {
                                                                        ...item,
                                                                        costs: filterBuild.next_cost
                                                                    };
                                                                }
                                                                return item; // Restituisci l'oggetto senza modifiche se l'ID non corrisponde
                                                            })
                                                        }));
                                                    })
                                                    .catch((e) => console.log(e))
                                                        
                                                    this.setState(prevState => ({
                                                        cityBuildings: prevState.cityBuildings.map(item => {
                                                            if (item.buildInfo.id === row.build.id) {
                                                                // Aggiorna l'oggetto con l'ID corrispondente
                                                                
                                                                return {
                                                                    ...item,
                                                                    level: row.level
                                                                };
                                                            }
                                                            return item; // Restituisci l'oggetto senza modifiche se l'ID non corrisponde
                                                        })
                                                    }));
    
                                                    
    
                                                    this.setState(prevState => ({
                                                        buildingsQueue: prevState.buildingsQueue.filter(item => item.id !== row.id)
                                                    }));
                                                    
                                                    
                                                    return <span>Completo</span>;
                                                }
                                            } else {
                                                // Render a countdown
                                                return <span>{days} {month} {years} {hours}:{minutes}:{seconds}</span>;
                                            }
                                        };
                                        
                                        return <tr key={index}> 
                                                    <td>
                                                        <ToolTip  title={"Livello attuale"} overlay={<p style={{minWidth: 200}}><b><u>{ row.build.name}</u></b><br /> { row.build.description}</p>} >
                                                            <img className={[Style.development , Style.dev1].join(" ")} src={"https://etr.altervista.org/cdn-v1/buildings/" + row.build.image} />
                                                        </ToolTip>
                                                        <span>{row.build.name}</span>
                                                    </td>
                                                    <td>
                                                        <div style={{position: "relative", whiteSpace: "nowrap"}}>
                                                            <ToolTip title={<p className='text-grey-900'>Livello attuale</p>}>
                                                                <div className={Style.levelValue} title="Livello in costruzione">{row.level}</div>
                                                            </ToolTip>
                                                        </div>
                                                    </td>
                                                    <td>
                                                    <Countdown
                                                        date={row.end * 1000}
                                                        renderer={renderer}
                                                    />
                                                    </td>
                                                    <td>
                                                        <Button className="!min-w-[100px]" type="danger" title="" name="build" id={row.id} value="Costruisci" onClick={(e) => this.removeBuilder(e,row.id)}>Annulla</Button>
                                                    </td>
                                                </tr>;
                                    }) 
                                : ""
                            }
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <div className="col-md">
                        <h2 style={{marginBottom: 20}}>Lista strutture</h2>
                    </div>
                </div>
                <div>
                    <table style={{width: "100%"}} className={Style.table}>
                        <thead>
                            <tr>
                                
                                <th><ToolTip placement="bottom" title={<p>Lista strutture</p>}><MapPin size={17} /></ToolTip></th>
                                <th><ToolTip placement="bottom" title={<p>Livello</p>}><CellSignalFull size={17} /></ToolTip></th>
                                <th><ToolTip placement="bottom" title={<p>Risorse richieste</p>}><Coins size={17} /></ToolTip></th>
                                <th></th>
                                <th><ToolTip placement="bottom" title={<p>Azioni</p>}><GearSix size={17}/></ToolTip></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                buildings.length > 0 ? 
                                //buildings.forEach((row, index) => {
                                buildings.map((row, index) => {
                                    let lRes, lBui, lResBui;
                                    console.log(cityBuildings);
                                    const filterCityBuild = cityBuildings.filter((build) => build.buildInfo.id == row.id)[0];

                                    const checkResReq = resourcesCity.filter((res, i) => res.res_quantity < row.costs[i].cost); 
                                    const checkBuildReq = row.requirements.filter(requirement => {
                                        const matchingBuild = cityBuildings.find(build => build.buildInfo.id === requirement.id);
                                        // Controlla se non c'è una corrispondenza (matchingBuild non è definito) o se il livello del requisito è maggiore del livello della struttura
                                        return !matchingBuild || parseInt(matchingBuild.level) < parseInt(requirement.level);
                                    });
                                    
                                    
                                    
                                    if(checkResReq){
                                        lRes =  row.costs.map((rowNextLevel, index) => {
                                            if(resourcesCity[index].res_quantity > rowNextLevel.cost) return;
                                            
                                            return <span className='text-gray-900' style={{minWidth: 200, display: 'block'}}>Manca <b>{(rowNextLevel.cost -resourcesCity[index].res_quantity)} {rowNextLevel.name}  </b><br/></span>
                                        });
                                    
                                    }

                                    if(checkBuildReq){
                                        lBui =  row.requirements.map((rowNextLevel, index) => {
                                            
                                            return <span className='text-gray-900' style={{minWidth: 200, display: 'block'}}>Manca <b>{rowNextLevel.name} al livello {rowNextLevel.level}</b><br/></span>
                                        });
                                    
                                    }

                                    if(lRes || lBui){
                                        lResBui = lRes;
                                        lResBui.push(...lBui);
                                    }

                                    const formatSeconds = (secs) => {
                                        function pad(n) {
                                            return (n < 10 ? "0" + n : n);
                                        }
                                    
                                        var h = Math.floor(secs / 3600);
                                        var m = Math.floor(secs / 60) - (h * 60);
                                        var s = Math.floor(secs - h * 3600 - m * 60);
                                    
                                        return pad(h) + ":" + pad(m) + ":" + pad(s);
                                    }
                                    
                                    
                                    
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <ToolTip styleTile={{left: 110}} title={<p className='text-gray-900' style={{minWidth: 200}}><b><u>{row.name}</u></b><br /> {row.description}</p>} >
                                                    <img onClick={ (event) => this.openModal(event, row)} className={[Style.development , Style.dev1].join(" ")} src={"https://etr.altervista.org/cdn-v1/buildings/" + row.img} />
                                                </ToolTip>
                                                <span className='mx-1'>{row.name}</span>
                                            </td>
                                            <td>
                                                <div style={{position: "relative", whiteSpace: "nowrap"}}>
                                                    <ToolTip title={<p className='text-gray-900'>Livello attuale</p>}>
                                                        <div className={Style.levelValue} title="Livello attuale">{filterCityBuild === undefined ? 0 : filterCityBuild.level}</div>
                                                    </ToolTip>
                                                </div>
                                            </td>
                                            <td style={{minWidth: 200}}>
                                                <div className="initial">
                                                    { 
                                                        row.costs ? 
                                                            Object.values(row.costs).map((element, index) => {
                                                                

                                                                return (
                                                                    <span className="res-iconss flex justify-center" style={{marginRight: 0}} key={index}>
                                                                        <div className='mx-1'>{element.cost}</div>
                                                                        <div className='mt-2 mx-1'>
                                                                            <ToolTip title={element.cost + " " + element.name}>
                                                                                <img src={"https://etr.altervista.org/cdn-v1/icons/100x100/" + element.icon} width={25} alt={element.name} />
                                                                            </ToolTip>
                                                                        </div>
                                                                    </span>
                                                                );
                                                            })
                                                        : null
                                                    }
                                                </div>
                                            </td>

                                            <td>
                                                {formatSeconds(row.build_time) } 
                                            </td>
                                            <td>
                                                {
                                                    
                                                    checkResReq.length == 0 && checkBuildReq.length == 0 ?
                                                        <Button className="!min-w-[100px]" type="submit" title="" name="build" id={row.id} value="Costruisci" onClick={(e) => this.handleConstruction(e,row.id)}>Costruisci</Button>
                                                    :
                                                        <ToolTip title={lResBui} styleTile={{left: -90}}>
                                                            <Info />
                                                        </ToolTip>
                                                        
                                                        
                                                }
                                            </td>
                                        </tr>
                                    );
                                }) : ""
                            }
                        </tbody>
                    </table>
                </div>

                {this.state.modalInfo.start ? <div className="overlayContent"></div>: <></> }
                {/* <Modal id={this.state.modalInfo.id} action={this.stateModal} show={this.state.modalInfo.start} title={this.state.modalInfo.title} body={this.state.modalInfo.body} bck={true} /> */}
                <Modal 
                    id={this.state.modalInfo.id} 
                    action={() => this.stateModal()} 
                    show={this.state.modalInfo.start} 
                    title={this.state.modalInfo.title ? this.state.modalInfo.title : "undefined"} 
                    body={this.state.modalInfo.body ? 
                            this.state.modalInfo.body
                        : "undefined" } 
                    bck={false} 
                    iconHelp={<SealQuestion size={23} className='mt-1' />}
                    iconClose={<X size={23} className='mt-1' />}
                />
            </div>
        );
    }
}


  
export default Index;