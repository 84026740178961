import React from 'react'
import {City} from 'ethernial-city'
import {Header} from 'ethernial-ui';

import {CityReq, CityResReq} from '../requests/cityReq';
import { GlobalFunctionsProvider } from '../global_request';

class Layout extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            resources: [],
            cityBuildings: []
        };
    }

    async componentDidMount(){
        const req = await CityReq();
        if(!req) return
        this.setState({
            resources: req.city.resources,
            cityBuildings: req.city.builds
        });
    }


    reload =(e) => {
        if(e.resources === null || e.cityBuildings === null) return
        this.setState({
            resources: e.resources,
            cityBuildings: e.cityBuildings
        });
    }

    render(){
        const { resources, cityBuildings} = this.state;
        if(resources.length == 0)return 

        const options = [{
            navLink: {
              resources: "",
              city: "/",
              map: "/Map",
              stat: "",
              report: "",
              message: "",
              quest: "",
            },
            resources: resources,
            maxCap: 5000,
            umor: 10,
            gold: 9
          }]
          
        // Clonare gli elementi figli e aggiungere il props desiderato
        const childrenWithProps = React.Children.map(this.props.children, child =>
            React.cloneElement(child, { 
                resources: resources,
                cityBuildings: cityBuildings
            })
        );

        

        return (
            <GlobalFunctionsProvider reload={this.reload} >
                <div className="ethernial-ui">
                    <Header className="fixed !min-h-[110px]" options={options} />
                    {childrenWithProps}
                </div>
            </GlobalFunctionsProvider>
        );
    }
    
}

export default Layout;