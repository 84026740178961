import React, { Component} from 'react';
import Style from './style.module.css';
import {ToolTip} from 'ethernial-ui';
import {CellSignalFull, Rocket, Planet } from '@phosphor-icons/react'

class ModalInfo extends Component{

    constructor(props){
        super(props);

        this.state = {
            buildInfo: {
                "id": 1,
                "name": "Main",
                "function": 1,
                "maxLevel": 10,
                "resourceCosts": [
                {
                    "buildingId": 0,
                    "resourceId": 0,
                    "resource": {
                    "id": 0,
                    "name": "string",
                    "startAmount": 0,
                    "isPopulation": true,
                    "icon": "string"
                    },
                    "level": 0,
                    "formula": "string"
                }
                ],
                "production": [
                {
                    "buildingId": 0,
                    "resourceId": 0,
                    "resource": {
                    "id": 0,
                    "name": "string",
                    "startAmount": 0,
                    "isPopulation": true,
                    "icon": "string"
                    },
                    "level": 0,
                    "formula": "string"
                }
                ],
                "buildingRequisites": [
                {
                    "buildingId": 0,
                    "sourceBuildingId": 0,
                    "level": 0
                }
                ],
                "timeCosts": [
                {
                    "level": 0,
                    "formula": "string"
                }
                ]
            }
        }
    }

    render(){
        const { body } = this.props;
        console.log(body);

        return <>
            <div style={{margin: '20px 15px', minWidth: 572}}>
                <div className="price-card-icon left">
                    <img src={ "https://etr.altervista.org/cdn-v1/buildings/" + body.img} alt="" />
                </div>
                <div className="price-card-txt left">
                    {body.description} <br />
                    Livello massimo: {body.maxLevel}.<br />
                    Livello Attuale: 0. <br />
                    Capacità livello successivo: 4220.</div>
                <div className="clr"></div>
                <div style={{display: 'inline-block', marginTop: 15,width: '100%'}}>
                    <p>I prezzi nella tabella sono influenzati dal livello di Architettura.</p>

                    <table style={{width: "100%", border: 'none', borderTop: '1px dashed #998C70'}} className={Style.table}>
                        <thead>
                            <tr>
                                <th style={{background: 'none'}}><ToolTip title={<p>Livello</p>}><CellSignalFull /></ToolTip></th>
                                <th style={{background: 'none'}}><ToolTip title={<p>Capacità</p>}><Planet /></ToolTip> </th>
                                <th style={{background: 'none'}}><ToolTip title={<p>Risorse richieste</p>}><Rocket /></ToolTip></th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr>
                                <td className="text-center">1</td>
                                <td className="text-center">2500</td>
                                <td style={{minWidth: 200}} className="text-center">
                                    <div className="flex" style={{}}>
                                        <span className="res-iconss flex justify-center" style={{marginRight: 0}} key={0}>
                                            <div className='mx-1'>1000</div>
                                            <div className='mt-2 mx-1'>
                                                <ToolTip title={"Legno"}>
                                                    <img src={"https://etr.altervista.org/cdn-v1/icons/100x100/ferro.png"} width={25} alt={"Legno"} />
                                                </ToolTip>
                                            </div>
                                        </span>
                                        <span className="res-iconss flex justify-center" style={{marginRight: 0}} key={0}>
                                            <div className='mx-1'>1000</div>
                                            <div className='mt-2 mx-1'>
                                                <ToolTip title={"Legno"}>
                                                    <img src={"https://etr.altervista.org/cdn-v1/icons/100x100/argilla.png"} width={25} alt={"Legno"} />
                                                </ToolTip>
                                            </div>
                                        </span>
                                        <span className="res-iconss flex justify-center" style={{marginRight: 0}} key={0}>
                                            <div className='mx-1'>1000</div>
                                            <div className='mt-2 mx-1'>
                                                <ToolTip title={"Legno"}>
                                                    <img src={"https://etr.altervista.org/cdn-v1/icons/100x100/popolazione.png"} width={25} alt={"Legno"} />
                                                </ToolTip>
                                            </div>
                                        </span>
                                        <span className="res-iconss flex justify-center" style={{marginRight: 0}} key={0}>
                                            <div className='mx-1'>1000</div>
                                            <div className='mt-2 mx-1'>
                                                <ToolTip title={"Legno"}>
                                                    <img src={"https://etr.altervista.org/cdn-v1/icons/100x100/legno.png"} width={25} alt={"Legno"} />
                                                </ToolTip>
                                            </div>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">2</td>
                                <td className="text-center">4500</td>
                                <td style={{minWidth: 200}} className="text-center">
                                <div className="flex" style={{}}>
                                        <span className="res-iconss flex justify-center" style={{marginRight: 0}} key={0}>
                                            <div className='mx-1'>1000</div>
                                            <div className='mt-2 mx-1'>
                                                <ToolTip title={"Legno"}>
                                                    <img src={"https://etr.altervista.org/cdn-v1/icons/100x100/ferro.png"} width={25} alt={"Legno"} />
                                                </ToolTip>
                                            </div>
                                        </span>
                                        <span className="res-iconss flex justify-center" style={{marginRight: 0}} key={0}>
                                            <div className='mx-1'>1000</div>
                                            <div className='mt-2 mx-1'>
                                                <ToolTip title={"Legno"}>
                                                    <img src={"https://etr.altervista.org/cdn-v1/icons/100x100/argilla.png"} width={25} alt={"Legno"} />
                                                </ToolTip>
                                            </div>
                                        </span>
                                        <span className="res-iconss flex justify-center" style={{marginRight: 0}} key={0}>
                                            <div className='mx-1'>1000</div>
                                            <div className='mt-2 mx-1'>
                                                <ToolTip title={"Legno"}>
                                                    <img src={"https://etr.altervista.org/cdn-v1/icons/100x100/popolazione.png"} width={25} alt={"Legno"} />
                                                </ToolTip>
                                            </div>
                                        </span>
                                        <span className="res-iconss flex justify-center" style={{marginRight: 0}} key={0}>
                                            <div className='mx-1'>1000</div>
                                            <div className='mt-2 mx-1'>
                                                <ToolTip title={"Legno"}>
                                                    <img src={"https://etr.altervista.org/cdn-v1/icons/100x100/legno.png"} width={25} alt={"Legno"} />
                                                </ToolTip>
                                            </div>
                                        </span>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    }
}

export default ModalInfo;