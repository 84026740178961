// Game.js
import React from 'react';
import Phaser from 'phaser';
import VillageScene from './VillageScene';
import MapScene from './MapScene';


class Game extends React.Component {
  componentDidMount() {
    const config = {
      type: Phaser.AUTO,
      width: window.innerWidth,
      height: window.innerHeight,
      scene: VillageScene, // Imposta solo la scena mappa inizialmente
    };

    this.game = new Phaser.Game(config);
  }

  render() {
    return (
      <div>
        {/* Aggiungi il pulsante per la transizione */}
        <button onClick={this.transitionToMap}>Vai alla Mappa</button>
          <button onClick={this.transitionToVillage}>Torna al Villaggio</button>
      </div>
    );
  }

  transitionToMap = () => {
    const { game } = this;
  
    // Controlla se la scena del villaggio è attiva
    const villageScene = game.scene.getScene('VillageScene');
    if (villageScene && villageScene.scene.isActive()) {
      // Ferma e rimuovi la scena del villaggio
      villageScene.scene.stop();
      villageScene.sound.stopAll();
      villageScene.sound.removeAll();
      game.scene.remove('VillageScene');
    }
  
    // Avvia o crea la scena della mappa
    if (!game.scene.isActive('MapScene')) {
      game.scene.add('MapScene', MapScene, true);
    }
  };
  
  transitionToVillage = () => {
    const { game } = this;
  
    const mapScene = game.scene.getScene('MapScene');
    if (mapScene && mapScene.scene.isActive()) {
      // Ferma e rimuovi la scena della mappa 
      
      mapScene.scene.stop();
      game.scene.remove('MapScene');
    }
  
    // Avvia o crea la scena della mappa
    if (!game.scene.isActive('VillageScene')) {
      
      game.scene.add('VillageScene', VillageScene, true);
    }
  
    // Avvia o crea la nuova scena del villaggio
  };

}


export default Game;
