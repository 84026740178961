import axios from 'axios';
const FormData = require('form-data');

axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT_SERVER;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('eth_token');

async function BuildsList() {
    try {
        
        const response = await axios.get('/?pg=Buildings');
        return response.data;
    } catch (error) {
        console.error('Errore nella richiesta:', error);
        throw error; // Rilancia l'errore per gestirlo nel chiamante
    }
}
async function BuildsQueue() {
    try {
        
        const response = await axios.get('/?pg=Buildings&act=Queue');
        return response.data;
    } catch (error) {
        console.error('Errore nella richiesta:', error);
        throw error; // Rilancia l'errore per gestirlo nel chiamante
    }
}

async function BuildSet(buildID) {

    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

    try {
        // Creazione dell'oggetto FormData
        const formData = new URLSearchParams({buildId: buildID}).toString();
        const response = await axios.post('/?pg=Buildings&act=Build', formData);
        return response.data;
    } catch (error) {
        console.error('Errore nella richiesta:', error);
        throw error; // Rilancia l'errore per gestirlo nel chiamante
    }
}

async function BuildCancel(buildID) {

    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

    try {
        // Creazione dell'oggetto FormData
        const formData = new URLSearchParams({queueId: buildID}).toString();
        const response = await axios.post('/?pg=Buildings&act=Cancel', formData);
        return response.data;
    } catch (error) {
        console.error('Errore nella richiesta:', error);
        throw error; // Rilancia l'errore per gestirlo nel chiamante
    }
}


export {
    BuildsList,
    BuildSet,
    BuildsQueue,
    BuildCancel
};
